<template>
    <!--维修项目展示页面 -->
    <div>
        <div class="table_content">

            <!-- 表格内容 -->
            <commonTitle></commonTitle>
            <div class="search">
                <el-form ref="form" :model="form" label-width="100px" :inline="true">
                    <el-form-item label="维修项目名称:">
                        <el-input v-model="form.name" placeholder="请输入维修项目名称" size="mini"></el-input>
                    </el-form-item>
                    <el-form-item label="使用类型:">
                        <el-select v-model="form.type" placeholder="请选择" size="mini"></el-select>
                    </el-form-item>
                    <el-form-item label="进度状态:">
                        <el-select v-model="form.status" placeholder="请选择" size="mini"></el-select>
                    </el-form-item>

                    <button @click.prevent="common()"  style="cursor: pointer">搜索</button>
                    <button @click.prevent="add()" style="cursor: pointer" type="primary" plain>+ 项目新增</button>
                </el-form>
            </div>
            <!--表格-->
            <template>
                <el-card>
                <el-table
                        ref="multipleTable"
                        :data="tableData"
                        tooltip-effect="dark"
                        style="width: 100%"
                        stripe
                        v-loading="loading"
                        :element-loading-text="loadingText"
                >
                    <el-table-column
                            property="name"
                            label="维修项目名称"
                            show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column
                            property="typeName"
                            label="使用类型"
                            show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column
                            property="object"
                            label="维修对象"
                            show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column
                            property="scaleName"
                            label="维修规模"
                            show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column
                            property="actResult"
                            label="审批结果"
                            :formatter="formatActResult"
                            show-overflow-tooltip>
                    </el-table-column>

                    <el-table-column
                            property="createDate"
                            label="创建时间"
                            show-overflow-tooltip
                            :formatter="formatSettingTime"
                    >
                    </el-table-column>
                    <el-table-column
                            fixed="right"
                            label="操作"
                            width="150">
                        <template slot-scope="scope">


                            <el-tooltip class="item" effect="dark" content="进度查看" placement="top-start">
                                <i class="el-icon-menu" @click="publicVote(scope.row)" style="margin-left: 10px;cursor: pointer"></i>
                            </el-tooltip>

                            <el-tooltip class="item" effect="dark" content="删除" placement="top-start">
                                <i class="el-icon-delete" style="margin-left: 10px;cursor: pointer"
                                   @click="deleteRow(scope.$index, scope.row,tableData)"
                                   v-show="showDel(scope.row)"
                                ></i>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="下载分摊信息" placement="top-start">
                                <i class="el-icon-download" style="margin-left: 10px;cursor: pointer"
                                   @click="downRepairDetail(scope.row)"
                                ></i>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        background
                        style="margin-top: 50px"
                        layout="prev, pager, next"
                        :page-size="15"
                        @current-change="handleCurrentChange"
                        :total="totalNum">
                </el-pagination>
                </el-card>
            </template>
        </div>
    </div>
</template>

<script>

    import commonTitle from "../table/commonTitle";

    export default {
        name: "repairItem",
        components: {
            commonTitle
        },
        data() {
            return {
                url: this.$Config.base_server,
                loading: true,
                tableData: [],//表格数据
                totalNum: 15,
                form: {
                    name: ""
                },
                loadingText: '拼命加载中',
                data: {
                    limit: 15
                },
                publicData: {},
                time: {},
            }
        }, mounted() {
            this.common();
        }, methods: {
            common(currentPage = 0) {
                //初始化数据
                //获取所有的维修资金项目列表
                const _this = this;

                _this.data.page = currentPage;
                _this.data.repairName = _this.form.name;
                $.ajax({
                    url: _this.url + '/api-public/createRepair/list?access_token=' + localStorage.getItem("token"),
                    type: 'get',
                    data: _this.data,
                    contentType: 'application/json',
                    success: function (res) {
                        _this.loading = false;
                        _this.totalNum = res.count;
                        _this.tableData = res.data;
                    },
                    error: function (res) {
                        _this.loading = false;
                    }
                });
            }, formatSettingTime(row) {
                //格式化时间
                let date = new Date(row.createDate);
                let month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
                let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
                return date.getFullYear() + "-" + month + "-" + day;
            }, handleCurrentChange(val) {//当前页数改变
                this.loading = true;
                this.common(val)
            }, deleteRow(index, rows, tableData) {
                //删除按钮
                let _this = this;
                let _rows = rows;
                //删除相应楼幢
                this.$confirm('此操作将永久删除该维修信息以及已经存在的分摊明细, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(function () {
                    $.ajax({
                        url: _this.url + "/api-public/createRepair/delete/" + _rows.id + "?access_token=" + localStorage.getItem("token"),
                        type: 'delete',
                        success: function (data) {
                            _this.loading = false;
                            if (data.resp_code == 0) {
                                tableData.splice(index, 1);
                                //成功
                                _this.$message({
                                    type: 'success',
                                    message: data.resp_msg
                                });
                            }
                            if (data.resp_code == 1) {
                                //失败
                                _this.$message({
                                    type: "error",
                                    message: data.resp_msg
                                });
                            }
                        },
                        error: function (res) {
                            _this.loading = false;
                        }
                    });
                }).catch(function () {

                });
            }, showDel(row) {
                //用于判断是否隐藏删除按钮
                if ((row.vote == '' || row.vote == null)&&row.actResult=='4') {
                    return true;
                } else {
                    return false;
                }
            }, publicVote(row) {
                const _this = this;
                debugger;
                if (row.type != "397653397651587072" && row.actResult =='4'){
                    $.ajax({
                        url: _this.url + '/api-public/repairShareDetail/findStartEndTimeByRepairId?id=' + row.id +'&access_token=' + localStorage.getItem("token"),
                        type: 'get',
                        contentType: 'application/json',
                        success: function (res) {
                            _this.publicData.endTime = res[0].voteEndTime
                            _this.publicData.createRepairId = row.id
                            //投票公示
                            _this.$router.push({path: 'votePublic', query: {repairId:row.id, publicData: _this.publicData,createRepairId: row.id,endTime: res[0].voteEndTime,name: row.name}});
                        }
                    });
                }else {
                    _this.$router.push({path: 'WuYeManagement', query: {createRepairId: row.id,active: row.active,fundingPlan: 2,txtNumber: 2,name: row.name}});
                  }
            },
            add() {
                //添加按钮
                this.$router.push("wuYeManagement");
            }, subAct(row) {
                const that = this;

                this.$confirm('确定提交该维修项目, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(function () {
                    that.loadingText = "提交审核中,请稍后...";
                    that.loading = true;
                    //提交审批按钮
                    $.ajax({
                        url: that.url + "/api-hxworkflow/act/deal/startMyFlow?access_token=" + localStorage.getItem("token"),
                        data: {businessId: row.id, actKey: 'repair_flow'},
                        type: 'post',
                        success: function (res) {
                            that.loading = false;
                            if (res.resp_code == 0) {
                                that.common();
                                that.$message({
                                    type: 'success',
                                    message: '流程提交成功！'
                                });
                            } else {
                                that.loading = false;
                                that.$message({
                                    type: 'error',
                                    message: res.resp_msg
                                });
                            }
                        }, error: function (res) {
                            if (res.responseJSON.resp_code == 9999) {
                                that.loading = false;
                                that.$message({
                                    type: 'error',
                                    message: "提交异常"
                                });
                            }
                        }
                    });
                }).catch(function () {

                });
            }, formatActResult(row) {
                //格式化审批结果
                if (row.actResult == 1) {
                    return "同意";
                } else if (row.actResult == 2) {
                    return "不同意";
                } else if (row.actResult == 3) {
                    return "审批中";
                } else if (row.actResult == 4) {
                    return "草稿";
                }
            }, downRepairDetail(row) {
                const _this = this;
                //下载分摊详情
                window.location.href = _this.url + "/api-public/repairShareDetail/exportExcel?access_token=" + localStorage.getItem("token") + "&repairId=" + row.id;
            }
        }
    }
</script>

<style scoped>

</style>